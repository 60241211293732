import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default class SolicitationDisclosure extends React.Component {
  render() {
    return (
      <Layout title="Solicitation Disclosure">
        <Seo title="Solicitation Disclosure"/>
        <div className="container">
          <div className="row">
            <section className="col">
              <h2 className="mb-3">Solicitation Disclosure</h2>
              
              <p>Hope for Native America is a division of Ron Hutchcraft Ministries, Inc. which is a 501(c)(3) organization. All gifts are tax deductible to the full extent allowed by law. Our policy is that all gifts designated for a specific project be applied to the project, with up to 10% used for administrative and fund-raising expenses. Occasionally we receive more contributions for a given project than can wisely be applied to that project. When that happens, we use these funds to meet a similar pressing need. We do not provide goods or services in consideration for contributions.</p>

              <p>Ron Hutchcraft Ministries, Inc. is audited annually by an independent public accounting firm. Descriptions of Ron Hutchcraft Ministries, Inc.'s programs and activities, and its audited financial statements, are available upon request at: Ron Hutchcraft Ministries, Inc., P.O. Box 400, Harrison AR 72602, or by calling (870) 741-2181. Contributions are solicited with the understanding that Ron Hutchcraft Ministries, Inc. has complete control over the use of all donated funds.</p>

              <p>Some states require organizations soliciting funds to register with an agency responsible for regulating charities. Several states exempt faith-based, religious organizations from solicitation registration. Ron Hutchcraft Ministries, Inc. is registered where required.</p>

              <p>Ron Hutchcraft Ministries, Inc. is a member in good standing of the Evangelical Council for Financial Accountability.</p>         
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

